import React, {Component} from 'react';
import Layout from '../components/Layout';

class Contact extends Component{
    render(){
        return(
            <Layout>
                <div>Who needs a Contact page when there is a card on the left with all my information?</div>
            </Layout>
        );
    };
}

export default Contact;